import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import asideCenterLogo from "labels/autodok/images/asideCenterLogo.svg";
import bg from "labels/autodok/images/bg.svg";
import headLogo from "labels/autodok/images/headLogo.svg";

const autodokLabelOptions = {
  name: "AutoDok",
  altName: "AutoDok",
  site: "https://autodok.co.il",
  supportEmail: "support@autodok.co.il",
  termsAndConditionLink: "https://autofont.co.il/autodok_terms-conditions/",
  privacyLink: "https://autofont.co.il/privacy_policy/",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "AutoDok",
  isSsoLogin: false,
  theme: "autodok",
  logo: {
    src: logo,
    alt: "Autodok logo",
  },
  asideCenterLogo: {
    src: asideCenterLogo,
    alt: "Autodok",
    style: {},
    slogan: '',
  },
  headLogo: {
    src: headLogo,
    alt: "Autodok head logo",
  },
  asidePoweredBy: true,
  brand: {
    primary: {
      10: "#6E6D730A",
      20: "#6E6D7333",
      40: "#6E6D7380",
      60: "#6E6D73CC",
      80: "#6E6D73",
      90: "#4D4C50",
      100: "#4D4C50",
    },
    secondary: {
      10: "#F9C11B0A",
      20: "#F9C11B33",
      40: "#F9C11B80",
      60: "#F9C11BCC",
      80: "#F9C11B",
      90: "#F9C11B",
      100: "#F9C11B",
    },
  },
  styles: {
    aside: {
      backgroundColor: '#6E6D73',
      backgroundImage: `url(${bg})`
    },
  },
};

export default autodokLabelOptions;
