import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import name from "./images/name.svg";
import bg from "./images/bg.svg";

const bdoLabelOptions = {
  name: "BDO",
  altName: "BDO",
  site: "https://www.bdo.co.il",
  supportEmail: "support@docs.bdo.co.il",
  termsAndConditionLink:
    "https://www.bdo.co.il/he-il/מדיניות-פרטיות-ותנאים-משפטיים",
  privacyLink: "https://www.bdo.co.il/he-il/מדיניות-פרטיות-ותנאים-משפטיים",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "BDO",
  theme: "bdo",
  isSsoLogin: false,
  logo: {
    src: logo,
    alt: "BDO logo",
  },
  asideCenterLogo: {
    src: name,
    alt: "BDO",
    style: {},
    slogan: 'ACCOUNTING Automation',
  },
  asidePoweredBy: true,
  brand: {
    primary: {
      10: "#5252520A",
      20: "#52525233",
      40: "#52525280",
      60: "#525252CC",
      80: "#525252",
      90: "#393939",
      100: "#393939",
    },
    secondary: {
      10: "#218F8B0A",
      20: "#218F8B33",
      40: "#218F8B80",
      60: "#218F8BCC",
      80: "#218F8B",
      90: "#218F8B",
      100: "#218F8B",
    },
  },
  styles: {
    aside: {
      backgroundColor: '#525252',
      backgroundImage: `url(${bg})`
    },
  },
};

export default bdoLabelOptions;
