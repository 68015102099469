import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import asideCenterLogo from "./images/asideCenterLogo.svg";
import asideTopBG from "./images/asideTopBG.svg";
import asideBottomBG from "./images/asideBottomBG.svg";

const nextAgeLabelOptions = {
  name: "NextAge",
  altName: "NextAge",
  site: "https://www.nextage.co.il",
  supportEmail: "info@nextage.co.il",
  termsAndConditionLink: "https://www.nextage.co.il/about",
  privacyLink: "https://www.nextage.co.il/privacy-policy",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "Nextage Online",
  isSsoLogin: false,
  theme: "nextAge",
  logo: {
    src: logo,
    alt: "NextAge logo",
  },
  asideCenterLogo: {
    src: asideCenterLogo,
    alt: "NextAge",
    style: {
      maxWidth: '100%',
    },
    slogan: '',
  },
  asidePoweredBy: false,
  brand: {
    primary: {
      10: "#0C71E70A",
      20: "#0C71E733",
      40: "#0C71E780",
      60: "#0C71E7CC",
      80: "#333A99",
      90: "#084FA1",
      100: "#002B55",
    },
    secondary: {
      10: "#00CE810A",
      20: "#00CE8133",
      40: "#00CE8180",
      60: "#00CE81CC",
      80: "#6167BB",
      90: "#00CE81",
      100: "#00CE81",
    },

  },

  styles: {
    aside: {
      backgroundColor: '#333A99',
      backgroundImage: `url(${asideTopBG}), url(${asideBottomBG}), linear-gradient(205deg, #323A9F 45.1%, #002B55 80.85%)`,
      style: {
        backgroundPosition: 'top right, bottom left',
      },
    },
  },
};

export default nextAgeLabelOptions;
