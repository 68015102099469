import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import asideCenterLogo from "./images/asideCenterLogo.svg";

const pegasusLabelOptions = {
  name: "Pegasus",
  altName: "PEGASUS",
  site: "https://www.pegasusconsulting.co.za",
  supportEmail: "help@pegasus.com",
  termsAndConditionLink: "https://www.pegasusconsulting.co.za/terms-conditions",
  privacyLink: "https://www.pegasusconsulting.co.za/privacy-policy",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "Pegasus Сonsulting",
  isSsoLogin: false,
  theme: "dokka",
  logo: {
    src: logo,
    alt: "Pegasus logo",
  },
  asideCenterLogo: {
    src: asideCenterLogo,
    alt: "PEGASUS",
    style: {},
    slogan: '',
  },
  asidePoweredBy: true,
  styles: {
    aside: {},
  },
};

export default pegasusLabelOptions;
