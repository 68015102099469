import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import asideCenterLogo from "./images/asideCenterLogo.svg";
import bg from "./images/bg.svg";

const profitianLabelOptions = {
  name: "Profitian",
  altName: "Profitian Finance",
  site: "https://app.profitian-tech.com",
  supportEmail: "support@profitian-tech.com",
  termsAndConditionLink: "https://www.profitian.co.il/term-of-use",
  privacyLink: "https://www.profitian.co.il/privacy-policy",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "Profitian Finance",
  theme: "profitian",
  isSsoLogin: false,
  logo: {
    src: logo,
    alt: "Profitian Finance logo",
  },
  asideCenterLogo: {
    src: asideCenterLogo,
    alt: "Profitian Finance",
    style: {},
  },
  loginSlogan: "Expect More",
  asidePoweredBy: true,
  appStoreDownloadLink: null,
  playMarketDownloadLink: null,
  brand: {
    primary: {
      10: "#7CAAC30A",
      20: "#7CAAC333",
      40: "#7CAAC380",
      60: "#7CAAC3CC",
      80: "#7CAAC3",
      90: "#567688",
      100: "#567688",
    },
    secondary: {
      10: "#FFC7290A",
      20: "#FFC72933",
      40: "#FFC72980",
      60: "#FFC729CC",
      80: "#FFC729",
      90: "#FFC729",
      100: "#FFC729",
    },
  },
  styles: {
    aside: {
      backgroundColor: '#FFC729',
      backgroundImage: `url(${bg})`
    },
  },
};

export default profitianLabelOptions;
