import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import asideCenterLogo from "./images/asideCenterLogo.svg";

const dokkaLabelOptions = {
  name: "Dokka",
  altName: "DOKKA",
  site: "https://www.dokka.com/",
  supportEmail: "support@dokka.com",
  termsAndConditionLink: "https://www.dokka.com/terms-conditions/",
  privacyLink: "https://www.dokka.com/privacy-policy/",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "Dokka A. I.",
  isSsoLogin: true,
  theme: "dokka",
  logo: {
    src: logo,
    alt: "Dokka logo",
  },
  asideCenterLogo: {
    src: asideCenterLogo,
    alt: "DOKKA",
    style: {},
    slogan: 'ACCOUNTING Automation'
  },
  asidePoweredBy: false,

  styles: {
    aside: {},
  },
};

export default dokkaLabelOptions;
