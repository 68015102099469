import alprimeLabelOptions from "./alprime";
import autodokLabelOptions from "./autodok";
import bdoLabelOptions from "./bdo";
import dokkaLabelOptions from "./dokka";
import madeLabelOptions from "./made";
import pegasusLabelOptions from "./pegasus";
import profitianLabelOptions from "./profitian";
import nextAgeOptions from "./nextAge";

const labelsData = {
  alprime: alprimeLabelOptions,
  autodok: autodokLabelOptions,
  bdo: bdoLabelOptions,
  dokka: dokkaLabelOptions,
  made: madeLabelOptions,
  pegasus: pegasusLabelOptions,
  profitian: profitianLabelOptions,
  nextAge: nextAgeOptions,
};

export default labelsData;
