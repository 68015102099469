import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import asideCenterLogo from "./images/asideCenterLogo.svg";
import bg from "./images/bg.svg";

const madeLabelOptions = {
  name: "Made",
  altName: "Made 360",
  site: "https://www.made.co.il/",
  supportEmail: "info@made.co.il",
  termsAndConditionLink: false,
  privacyLink: false,
  fi,
  fiAt,
  fi16,
  fi32,
  title: "Made 360",
  theme: "made",
  isSsoLogin: false,
  logo: {
    src: logo,
    alt: "Made 360",
  },
  asideCenterLogo: {
    src: asideCenterLogo,
    alt: "Made 360",
    style: {},
    slogan: '',
  },
  brand: {
    primary: {
      10: "#E4493D0A",
      20: "#E4493D33",
      40: "#E4493D80",
      60: "#E4493DCC",
      80: "#E4493D",
      90: "#9F342A",
      100: "#9F342A",
    },
    secondary: {
      10: "#B7A79B0A",
      20: "#B7A79B33",
      40: "#B7A79B80",
      60: "#B7A79BCC",
      80: "#B7A79B",
      90: "#B7A79B",
      100: "#B7A79B",
    },
  },
  styles: {
    aside: {
      backgroundColor: '#E4493D',
      backgroundImage: `url(${bg})`
    },
  },
};

export default madeLabelOptions;
