import fi from "./images/favicon.ico";
import fiAt from "./images/apple-touch-icon.png";
import fi16 from "./images/favicon-16x16.png";
import fi32 from "./images/favicon-32x32.png";
import logo from "./images/logo.svg";
import asideCenterLogo from "./images/asideCenterLogo.svg";

const alprimeLabelOptions = {
  name: "AlPrime",
  altName: "ALPRIME",
  site: "https://www.alprimecap.com",
  supportEmail: "help@alprimecap.com",
  termsAndConditionLink: "https://www.alprimecap.com/terms-conditions/",
  privacyLink: "https://www.alprimecap.com/privacy-policy/",
  fi,
  fiAt,
  fi16,
  fi32,
  title: "AlPrime Capital",
  isSsoLogin: false,
  theme: "dokka",
  logo: {
    src: logo,
    alt: "AlPrime logo",
  },
  asideCenterLogo: {
    src: asideCenterLogo,
    alt: "ALPRIME",
    style: {},
    slogan: '',
  },
  asidePoweredBy: false,
  styles: {},
};

export default alprimeLabelOptions;
